$(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('#hp-banners').slick({
		dots: false,
		infinite: true,
		speed: 1000,
		fade: true,
		rtl: dirRtlFlag,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		cssEase: 'linear',
		pauseOnHover: false
	});

	$(window).scroll(function() {
		var x = $(this).scrollTop(); /* scroll pos */
		var y = $("html").height();
		var z = x / y;

		curTop = (((z * 800) + 0));
		$("#banners-scroll").css("top", (curTop + 0) + "px");
	});

	// var hasWebRTC = navigator.getUserMedia ||
	//                 navigator.webkitGetUserMedia ||
	//                 navigator.mozGetUserMedia ||
	//                 navigator.msGetUserMedia;

	// if (!hasWebRTC) {
	//   $('.wrap-banner-scroll').css('position','static');
	// }

	// Get IE or Edge browser version
	var version = detectIE();

	// if (version === false) {
	//   document.getElementById('result').innerHTML = '<s>IE/Edge</s>';
	// } else if (version >= 12) {
	//   document.getElementById('result').innerHTML = 'Edge ' + version;
	// } else {
	//   document.getElementById('result').innerHTML = 'IE ' + version;
	// }

	// add details to debug result
	// document.getElementById('details').innerHTML = window.navigator.userAgent;

	/**
	 * detect IE
	 * returns version of IE or false, if browser is not Internet Explorer
	 */
	function detectIE() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}
		// var edge = ua.indexOf('Edg/');
		// if (edge > 0) {
		//   // Edge (IE 12+) => return version number
		//   // console.log(ua.substring(edge + 4, ua.indexOf('.', edge)));
		//   return parseInt(ua.substring(edge + 4, ua.indexOf('.', edge)), 10);
		// }

		//  var Firefox = ua.indexOf('Firefox/');
		// if (Firefox > 0) {
		//   return parseInt(ua.substring(Firefox + 8, ua.indexOf('.', Firefox)), 10);
		// }

		// other browser
		return false;
	}

	if (version) {
		$('.wrap-banner-scroll').css('position', 'static');
	} else {

	}

	///

	$('.client__slider').slick({
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 700,
		rtl: dirRtlFlag,
		responsive: [{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});



});
